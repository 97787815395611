import { UserContext } from "../../Context/UserContext";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { productsText, scriptText } from "../../../services/componentText";
import { Main } from "../../Main/Main";
import {
  fetchScript,
  deleteScript,
  fetchScriptTemplate,
} from "../../../api/script";
import {
  AddedNewButton,
  NavContainer,
  IconButton,
  TrashButton,
  IconLinkContainer,
  Title,
  ProductTitle,
} from "./ScriptDetails.styled";
import { BackLink } from "../../BackLink/BackLink";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import css from "./ScriptDetails.module.css";
import { useQuery } from "react-query";
import { fetchProduct } from "../../../api/product.js";
import { Modal } from "../../Modal/Modal.jsx";
import { ProductDetails } from "../../Products/ProductDetails/ProductDetails.jsx";


const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ScriptDetails = () => {
  const { language, currentUser } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [script, setScript] = useState({
    id: "",
    product_id: "",
    greetings_id: "",
    identyfying_needs_id: "",
    presentation_id: "",
    price_offer_id: "",
    crosstail_offer_id: "",
    handling_objections_id: "",
    close_deal: "",
    presentation_and_price_offer_text_ru: "",
    presentation_and_price_offer_text_ua: "",
  });
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsOpenModal] = useState(false);

  const [allProducts, setAllProducts] = useState([]);

  const { data: templatesData } = useQuery(
    "templatesData",
    fetchScriptTemplate
  );


  const [isPageRussian, setIsPageRussian] = useState(
    language === "russian" ? true : false
  );

  const isList = location?.pathname?.startsWith("/script/list");

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  useEffect(() => {
    if (!isList) {
      return;
    }

    const fetchData = async () => {
      const productIdFromPath = location?.pathname?.replace(
        /^\/script\/list\//,
        ""
      );

      const response = await fetchScript({ id: productIdFromPath });

      const fetchedScript = response?.data[0];

      if(fetchedScript?.id) {
        setScript(fetchedScript);
      }
      
    };

    fetchData();
  }, [location, isList]);

  useEffect(() => {
    if (allProducts?.length === 0) {
      const fetchCrossailData = async () => {
        if (templatesData?.data?.some((item) => item.id === script.crosstail_offer_id)) {
          try {
            const primaryProduct = await fetchProduct({ productId: script?.product_id });

  
            const template = templatesData?.data?.find(
              (item) => item?.id === script?.crosstail_offer_id
            );
  
            const crossailVariation = JSON.parse(
              template?.crossail_variation || "[]"
            );
  
            const crossailData = await Promise.all(
              crossailVariation?.map(async (item) => {
                const product = await fetchProduct({ productId: item.id });

                return product?.data?.[0]; 
              })
            );
  
            const validatedPrimaryProduct = primaryProduct?.data?.[0]
              ? [primaryProduct?.data[0]]
              : [];
              
            const validatedCrossailData = crossailData?.filter(Boolean); 
  
            setAllProducts((prevProducts) => [
              ...prevProducts,
              ...validatedCrossailData,
              ...validatedPrimaryProduct,
            ]);
          } catch (error) {
            console.error("Error fetching crossail products:", error);
          }
        }
      };
  
      fetchCrossailData();
    }
  }, [allProducts, script.crosstail_offer_id, templatesData, script.product_id]);
  
  

  const handleDelete = async (id) => {
    const response = await deleteScript(id);

    if (response?.status === 200) {
      toast.success(productsText[language][0]?.successRemoved);
      return navigate("/script/list");
    }
  };

  const handleClick = async (id) => {
    const findProduct = allProducts?.filter(
      (product) => id === product.id
    );

      setSelectedProduct(findProduct[0]);
      setIsOpenModal(true);

  };

  return (
    <Main>
      <NavContainer>
        {isList && <BackLink to="/script/list" />}
        <AddedNewButton
          onClick={() => setIsPageRussian(false)}
          active={!isPageRussian}
          disabled={!isPageRussian}
          style={{ marginLeft: "10px" }}
        >
          {scriptText[language][0]?.ukraineVersion}
        </AddedNewButton>
        <AddedNewButton
          onClick={() => setIsPageRussian(true)}
          active={isPageRussian}
          disabled={isPageRussian}
        >
          {productsText[language][0]?.russianVersion}
        </AddedNewButton>
        {accessLevel && (
          <IconLinkContainer>
            <IconButton to={`/script/change/${script?.id}`}>
              <FaEdit />
            </IconButton>
            <TrashButton onClick={() => handleDelete(script?.id)}>
              <FaTrash />
            </TrashButton>
          </IconLinkContainer>
        )}
      </NavContainer>
      {isModalOpen && (
        <Modal closeModal={() => setIsOpenModal(false)}>
          <ProductDetails data={selectedProduct} />
        </Modal>
      )}
      <div style={{ width: "90%", userSelect: "none" }}>
        <Title onClick={() => handleClick(script.product_id)}>
          {isPageRussian ? script.title_ru : script.title_ua}
        </Title>

        {script?.greetings_id && (
          <>
            {templatesData?.data
              .filter(
                (template) =>
                  Number(template.id) === Number(script.greetings_id)
              )
              .map((selectedTemplate) => {
                let templateDescriptionUA =
                  selectedTemplate?.template_describe_ua;
                let templateDescriptionRU =
                  selectedTemplate?.template_describe_ru;

                templateDescriptionUA = templateDescriptionUA?.replace(
                  /название/gi,
                  `${script?.title_ua}`
                );

                templateDescriptionRU = templateDescriptionRU?.replace(
                  /название/gi,
                  `${script?.title_ru}`
                );

                return (
                  <div
                    key={script.id}
                    className={css.scriptContent}
                    dangerouslySetInnerHTML={{
                      __html: isPageRussian
                        ? templateDescriptionRU
                        : templateDescriptionUA,
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                );
              })}
          </>
        )}

        {script?.identyfying_needs_id && (
          <>
            {templatesData?.data
              .filter(
                (template) =>
                  Number(template.id) === Number(script.identyfying_needs_id)
              )
              .map((selectedTemplate) => {
                let templateDescriptionUA =
                  selectedTemplate?.template_describe_ua;
                let templateDescriptionRU =
                  selectedTemplate?.template_describe_ru;

                templateDescriptionUA = templateDescriptionUA?.replace(
                  /название/gi,
                  `${script?.title_ua}`
                );

                templateDescriptionRU = templateDescriptionRU?.replace(
                  /название/gi,
                  `${script?.title_ru}`
                );

                return (
                  <div
                    key={script.id}
                    className={css.scriptContent}
                    dangerouslySetInnerHTML={{
                      __html: isPageRussian
                        ? templateDescriptionRU
                        : templateDescriptionUA,
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                );
              })}
          </>
        )}

        {script && (
          <div style={{ userSelect: "none" }}>
            <div
              className={css.productContent}
              dangerouslySetInnerHTML={{
                __html: isPageRussian
                  ? script?.presentation_and_price_offer_text_ru
                  : script?.presentation_and_price_offer_text_ua,
              }}
            />
          </div>
        )}

{script?.crosstail_offer_id && (
  <>
    {(() => {


      const productNames = allProducts?.map((product) => (
        <ProductTitle
          key={product.id}
          onClick={() => handleClick(product.id)}
          style={{ width: "80%" }}
        >
          {product.title_ua}
        </ProductTitle>
      ));

      return (
        <div>
          <div
            className={css.scriptContent}
            dangerouslySetInnerHTML={{
              __html: isPageRussian
                ? script.before_crossail_variation_ru || ""
                : script.before_crossail_variation_ua || "",
            }}
            style={{ marginBottom: "10px", marginTop: "15px" }}
          />
          <div
            className={css.scriptContent}
            style={{ marginBottom: "10px" }}
          >
            {productNames}
          </div>
          <div
            className={css.scriptContent}
            dangerouslySetInnerHTML={{
              __html: isPageRussian
                ? script.after_crossail_variation_ru || ""
                : script.after_crossail_variation_ua || "",
            }}
            style={{ marginBottom: "10px" }}
          />
        </div>
      );
    })()}
  </>
)}


        {templatesData?.data && (
          <>
            {templatesData?.data
              .filter(
                (template) =>
                  Number(template.category_id) === 8 &&
                  Number(template.category_display) === 1
              )
              .map((selectedTemplate) => {
                let templateDescriptionUA =
                  selectedTemplate?.template_describe_ua;
                let templateDescriptionRU =
                  selectedTemplate?.template_describe_ru;

                templateDescriptionUA = templateDescriptionUA?.replace(
                  /название/gi,
                  `${script?.title_ua}`
                );

                templateDescriptionRU = templateDescriptionRU?.replace(
                  /название/gi,
                  `${script?.title_ru}`
                );

                return (
                  <div
                    key={script.id}
                    className={css.scriptContent}
                    dangerouslySetInnerHTML={{
                      __html: isPageRussian
                        ? templateDescriptionRU
                        : templateDescriptionUA,
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                );
              })}
          </>
        )}

        {templatesData?.data && (
          <>
            {templatesData?.data
              .filter(
                (template) =>
                  Number(template.category_id) === 9 &&
                  Number(template.category_display) === 1
              )
              .map((selectedTemplate) => {
                const crossailVariation = JSON?.parse(
                  selectedTemplate?.crossail_variation || "[]"
                );

                const names = allProducts
                  ?.filter((product) =>
                    crossailVariation?.some(
                      (variation) => Number(variation.id) === Number(product.id)
                    )
                  )
                  ?.map((product) => (
                    <ProductTitle
                      key={product.id}
                      onClick={() => handleClick(product.id)}
                      style={{ width: "80%" }}
                    >
                      {product.title_ua}
                    </ProductTitle>
                  ));

                return (
                  <div
                    key={script.id}
                    className={css.scriptContent}
                    style={{ marginBottom: "10px" }}
                  >
                    {names}
                  </div>
                );
              })}
          </>
        )}

        {script?.close_deal && (
          <>
            {templatesData?.data
              .filter(
                (template) => Number(template.id) === Number(script.close_deal)
              )
              .map((selectedTemplate) => (
                <div
                  key={script.id}
                  className={css.scriptContent}
                  dangerouslySetInnerHTML={{
                    __html: isPageRussian
                      ? selectedTemplate?.template_describe_ru
                      : selectedTemplate?.template_describe_ua,
                  }}
                  style={{ marginBottom: "10px" }}
                />
              ))}
          </>
        )}

        {script?.handling_objections_id && (
          <>
            {templatesData?.data
              .filter(
                (template) =>
                  Number(template.id) === Number(script.handling_objections_id)
              )
              .map((selectedTemplate) => (
                <div
                  key={script.id}
                  className={css.scriptContent}
                  dangerouslySetInnerHTML={{
                    __html: isPageRussian
                      ? selectedTemplate?.template_describe_ru
                      : selectedTemplate?.template_describe_ua,
                  }}
                  style={{ marginBottom: "10px" }}
                />
              ))}
          </>
        )}
      </div>
    </Main>
  );
};
