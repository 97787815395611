import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Thead,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  SearchContainer,
  SearchInput,
  SearchButton,
  PrdocutAvailabilityContainer,
  PrdocutAvailabilityNameContainer,
  PrdocutAvailabilityStatusContainer,
} from "../ManagerProductAvailability/ProductAvailabilityManager.styled.js";
import { UserContext } from "../../Context/UserContext";
import { AiOutlineSearch } from "react-icons/ai";
import { productAvailabilityText } from "../../../services/componentText";
import { fetchProduct } from "../../../api/product";
import { fetchProductStatus } from "../../../api/productAvailability";
import { LanguageSelector } from "../../LanguageSelector/LanguageSelector";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Pagination } from "../../Pagination/Pagination";

export const ProductAvailabilityUser = () => {
  const { language } = useContext(UserContext);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isRussianTitle, setIsRussianTitle] = useState(
    language === "russian" ? true : false
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);

  const { data: productData } = useQuery(
    ["productData", pageLimit, currentPage, searchQuery],
    () =>
      fetchProduct({
        pageLimit,
        currentPage,
        sortBy: "title_ua",
        order: "ASC",
        searchQuery,
      })
  );

  useEffect(() => {
    if (productData) {
      setTotalPages(productData?.totalPages);
    }
  }, [productData, pageLimit]);


  const handleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(inputValue);
  };

  const { data: productStatus } = useQuery("productStatus", fetchProductStatus);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };


  const getProductAvailabilityStatus = (id) => {
    const findStatus = productStatus?.filter(
      (item) => Number(item?.product_status_id) === Number(id)
    );

    return language === "russian"
      ? findStatus[0]?.product_status_title_RU
      : findStatus[0]?.product_status_title_UA;
  };

  const checkStatusBackgroundColor = (id) => {
    const findStatus = productStatus?.filter(
      (item) => Number(item?.product_status_id) === Number(id)
    );

    if (findStatus[0]?.product_status_id === 1) {
      return "#27ae60";
    }

    if (findStatus[0]?.product_status_id === 2) {
      return "rgb(165, 52, 39)";
    }

    if (findStatus[0]?.product_status_id === 3) {
      return "rgb(231, 76, 60)";
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchContainer onSubmit={(e) => handleSearch(e)}>
          <SearchInput
            type="text"
            placeholder={
              productAvailabilityText[language][0]?.enterProductTitle
            }
            value={inputValue}
            onChange={handleInputChange}
          />
          <SearchButton type="submit" onClick={(e) => handleSearch(e)}>
            <AiOutlineSearch size="24px" />
          </SearchButton>
        </SearchContainer>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />

        <LanguageSelector
          language={isRussianTitle}
          onChange={(value) =>
            setIsRussianTitle(value === "true" ? true : false)
          }
        />
      </div>
      <Table>
        <Thead>
          <HeadTr>
            <Th style={{ width: "28px" }}>№</Th>
            <Th style={{ width: "450px" }}>{productAvailabilityText[language][0]?.productTitle}</Th>
            <Th style={{ width: "500px" }}>
              {" "}
              {productAvailabilityText[language][0]?.informationAboutVariation}
            </Th>
          </HeadTr>
        </Thead>
        <tbody>
          {productData?.data?.length > 0 && (
            <React.Fragment>
              {productData?.data?.map((item, index) => (
                <Tr key={item?.id} style={{userSelect: 'none'}}>
                      <Td style={{ width: "50px" }}>
                        {(currentPage - 1) * pageLimit + (index + 1)}
                      </Td>
                      <Td style={{ width: "472px" }}>
                        {isRussianTitle ? item.title_ru : item.title_ua}
                      </Td>
                      <Td style={{ width: "522px" }}>
                        {item?.product_availability &&
                          Array.isArray(item.product_availability) &&
                          item?.product_availability?.map((product) => (
                            <PrdocutAvailabilityContainer
                              key={product?.name + item.id}
                              checkStatus={checkStatusBackgroundColor(
                                product?.status
                              )}
                            >
                              <PrdocutAvailabilityNameContainer>
                                {product?.name}
                              </PrdocutAvailabilityNameContainer>
                              <PrdocutAvailabilityStatusContainer>
                                {getProductAvailabilityStatus(product?.status)}
                              </PrdocutAvailabilityStatusContainer>
                            </PrdocutAvailabilityContainer>
                          ))}
                      </Td>


                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};
