import { Main } from "../../Main/Main";
import React, { useState, useEffect, useContext } from "react";
import { Modal } from "../../Modal/Modal";
import { makeRequestSQL } from "../../../api/api";
import { useMutation } from "react-query";
import {
  formatDateSchedule,
  formatDateToInput,
} from "../../../helpers/dateAndTime/formatDate";
import { ClipLoader } from "react-spinners";
import { useQuery } from "react-query";
import { UserContext } from "../../Context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "../../Buttons/IconButton.styled";
import { FaTrash } from "react-icons/fa";
import { MdPublishedWithChanges, MdOutlineAddCircle } from "react-icons/md";
import { weekDays, scheduleText } from "../../../services/componentText";
import {
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  SaveButton,
  AddedTimeContainer,
  AddedTimeInput,
  MonthButton,
  ScheduleMeNavContainer,
  DataSelect,
  DataOption,
  AddScheduleContainerModal,
  Thead,
  TableContainer,
  ChooseDataContainer,
  CopyNavContainer,
  CategoryContainer,
  TrashButton,
  EmployeeSelect,
} from "./GeneralSchedule.styled";
import { fetchOtherInformations } from "../../../api/users";
import {
  fetchAllSchedule,
  fetchCategories,
  deleteScheduleByDay,
  createScedule,
  updateSchedule,
} from "../../../api/schedule";
import { fetchUsers } from "../../../api/users";

const generateMonthDatesArray = (year, month, daysInMonth) => {
  const datesArray = [];
  for (let day = 1; day <= daysInMonth; day++) {
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    datesArray.push(formattedDate);
  }
  return datesArray;
};

export const GeneralSchedule = () => {
  const { language } = useContext(UserContext);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1)?.getDay();

  const [daysInMonth, setDaysInMonth] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [timeSlots, setTimeSlots] = useState([{ start: "", end: "" }]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [errorCount, setErrorCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedScheduleID, setSelectedScheduleID] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(1);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [filteredDays, setFilteredDays] = useState(daysInMonth);
  const [time, setTime] = useState("");
  const [selectedJobTitle, setSelectedJobTitle] = useState(3);

  const { data: informationData } = useQuery(
    "information",
    fetchOtherInformations
  );

  const handleConfirmDelete = async () => {
    const response = await deleteScheduleByDay({
      employee_id: selectedEmployeeId,
      day: await formatDateToInput(
        `${currentYear}-${currentMonth}-${selectedDay}`
      ),
      status: 2,
    });
    if (response.status === 200) {
      toast.success(scheduleText[language][0]?.successDelete);
      setModalOpen(false);
      refetch();
    }
  };

  const handleDeleteScheduleForMonth = async (employeeId) => {
    const monthDatesArray = await generateMonthDatesArray(
      currentYear,
      currentMonth,
      daysInMonth?.length
    );

    const response = await deleteScheduleByDay({
      employee_id: employeeId,
      day: monthDatesArray,
      status: 2,
    });

    if (response.status === 200) {
      toast.success(scheduleText[language][0]?.successDelete);
      setModalOpen(false);
      refetch();
    }
  };

  const fetchAllSchedules = async () => {
    const response = await fetchAllSchedule({
      year: currentYear,
      month: currentMonth,
      id_status: 2,
      job_titles: selectedJobTitle,
    });

    const formattedDataByEmployee = {};

    for (const [employeeId, employeeData] of Object.entries(response || {})) {
      const formattedData = employeeData?.schedules?.reduce((acc, item) => {
        const date = new Date(item.day);
        const dayNumber = date.getDate();

        if (!acc[dayNumber]) {
          acc[dayNumber] = {
            timeSlots: [],
            id_schedule: null,
          };
        }

        acc[dayNumber].id_schedule = item.id_schedule;

        acc[dayNumber].timeSlots.push({
          id_schedule: item.id_schedule,
          start: item.start_time ? item.start_time?.slice(0, 5) : "",
          end: item.end_time ? item.end_time?.slice(0, 5) : "",
          schedule_category_id: item.id_categorie,
          schedule_categorie_name_UA: item.schedule_categorie_name_UA,
          schedule_categorie_name_RU: item.schedule_categorie_name_RU,
        });

        return acc;
      }, {});

      formattedDataByEmployee[employeeId] = {
        schedules: formattedData,
        name: employeeData?.employeeName,
        group:
          language === "russian"
            ? employeeData?.employeeGroup?.groupe_name_RU
            : employeeData?.employeeGroup?.groupe_name_UA,
      };
    }

    return formattedDataByEmployee;
  };

  const { data: users } = useQuery(["getAllEmployees"], () =>
    fetchUsers({ sortBy: "id_employee", order: "ASC", isBlocked: "0" })
  );

  const { data: categories } = useQuery("categoriesData", fetchCategories);

  const {
    data: schedule,
    refetch,
    isLoading,
    error,
  } = useQuery(
    ["scheduleData", currentYear, currentMonth, selectedJobTitle],
    () => fetchAllSchedules(currentYear, currentMonth, selectedJobTitle)
  );

  const updateSingleSlotToServer = async (slot, index) => {
    const dataForServer = await prepareDataForSingleSlot(slot);
    if (dataForServer?.id_schedule) {
      await updateSchedule({
        id_schedule: dataForServer.id_schedule,
        data: dataForServer,
      });
    } else {
      await createScedule(dataForServer);
    }

    toast.success(scheduleText[language][0]?.successSlot);
    setModalOpen(false);
    refetch();
  };

  const MAX_RETRIES = 1;
  const RETRY_DELAY = 180000;

  useEffect(() => {
    if (error && !isFetching && errorCount < MAX_RETRIES) {
      setTimeout(() => {
        refetch({
          year: currentYear,
          month: currentMonth,
          status: 2,
        });
        setErrorCount((prev) => prev + 1);
      }, RETRY_DELAY);
    } else if (errorCount >= MAX_RETRIES) {
      setErrorCount(0);
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, currentYear, currentMonth, errorCount, isFetching]);

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    const daysArray = [...Array(days).keys()].map((day) => day + 1);
    setDaysInMonth(daysArray);
    setFilteredDays(daysArray);
    const mockData = {};
    setScheduleData(mockData);
  }, [currentMonth, currentYear]);

  useEffect(() => {
    if (error) {
      toast.error(scheduleText[language][0]?.errorResponse, error);
    }
    if (schedule) {
      setScheduleData(schedule);
      setIsFetching(false);
    }
    if (categories) {
      setAllCategories(categories);
    }
  }, [schedule, error, language, categories]);

  const addTimeSlot = () => {
    setTimeSlots((prev) => [...prev, { start: "", end: "" }]);
  };

  const handleDayClick = (day, employeeId) => {
    setSelectedDay(day);
    setSelectedEmployeeId(employeeId);
    const employeeSchedule = combinedData[employeeId]?.schedules[day];
    if (employeeSchedule) {
      setSelectedCategory(employeeSchedule.category);
      setTimeSlots(employeeSchedule.timeSlots);
    } else {
      setTimeSlots([{ start: "", end: "" }]);
    }

    setSelectedScheduleID(
      combinedData[employeeId]?.schedules[day]?.id_schedule
    );

    setModalOpen(true);
  };

  const mutation = useMutation(createScedule, {
    onSuccess: () => {
      setModalOpen(false);
      toast.success(scheduleText[language][0]?.successSchedule);
      refetch();
    },
    onError: (error) => {
      toast.error(scheduleText[language][0]?.errorSchedule, error);
    },
  });

  const updateTimeSlot = (index, field, value) => {
    const updatedSlots = [...timeSlots];
    // if (field === 'end' && value < updatedSlots[index].start) {
    //   toast.warning(scheduleText[language][0]?.warningEndSlot);
    //   return;
    // }

    // if (
    //   field === 'start' &&
    //   updatedSlots[index].end &&
    //   value > updatedSlots[index].end
    // ) {
    //   toast.warning(scheduleText[language][0]?.warningStartSlot);
    //   return;
    // }

    if (selectedCategory?.schedule_category_id >= 11) {
      const dataToSend = prepareDataForServer({
        [selectedDay]: timeSlots,
      });

      createScedule(dataToSend)
        .then(() => {
          toast.success(scheduleText[language][0]?.successResponseDataBase);
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message || error.message;
          toast.error(errorMessage);
        });
    }

    updatedSlots[index][field] = value;
    setTimeSlots(updatedSlots);
  };

  const handleSave = () => {
    const fullScheduleData = prepareDataForServer();
    mutation.mutate(fullScheduleData);
  };

  const prepareDataForServer = () => {
    const results = [];
    const categoryId = selectedCategory?.schedule_category_id;

    if (!categoryId) {
      toast.error(
        `${scheduleText[language][0]?.categoryIdMissing} ${selectedDay}`
      );
      return results;
    }

    timeSlots.forEach((slot) => {
      const startTime = slot?.start?.trim() || null;
      const endTime = slot?.end?.trim() || null;

      if (categoryId <= 10 && (!startTime || !endTime)) {
        toast.error(
          language === "russian"
            ? `Для дня ${selectedDay} с идентификатором категории ${categoryId} необходимы поля start_time и end_time.`
            : `Для дня ${selectedDay} з ідентифікатором категорії ${categoryId} необхідні поля start_time та end_time.`
        );
        return;
      }

      results.push({
        employee_id: selectedEmployeeId,
        day: formatDateSchedule(currentYear, currentMonth, selectedDay),
        start_time: startTime,
        end_time: endTime,
        id_categorie: categoryId,
        id_status: 2,
      });
    });

    return results;
  };

  const correctedFirstDay = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;

  const daysWithEmptySlots = new Array(7).fill(null);

  daysInMonth?.forEach((day, index) => {
    const position = correctedFirstDay - 1 + index;
    daysWithEmptySlots[position] = day;
  });

  const areTimeSlotsValid = () => {
    if (selectedCategory?.schedule_category_id >= 11) {
      return true;
    }

    return timeSlots.every((slot) => slot.start < slot.end);
  };

  const changeMonth = (direction) => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear((prev) => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear((prev) => prev - 1);
    } else {
      setCurrentMonth((prev) => prev + direction);
    }
  };

  const prepareDataForSingleSlot = (slot) => {
    return {
      employee_id: selectedEmployeeId,
      id_schedule: slot.id || selectedScheduleID,
      start_time:
        selectedCategory?.schedule_category_id <= 10
          ? slot?.start.trim()
          : null,
      end_time:
        selectedCategory?.schedule_category_id <= 10 ? slot?.end.trim() : null,
      id_categorie: slot.category || selectedCategory?.schedule_category_id,
      id_status: 2,
      day: selectedDay,
    };
  };
  const combinedData = { ...scheduleData };

  users?.usersData?.forEach((user) => {
    if (
      !combinedData[user.id_employee] &&
      Number(selectedJobTitle) !== 0 &&
      Number(user.id_job_title) === selectedJobTitle
    ) {
      combinedData[user.id_employee] = {
        name: user.full_name,
        group:
          language === "russian" ? user.groupe_name_ru : user.groupe_name_ua,
        schedules: {},
      };
    }
  });

  const handleApplyCustomSchedule = () => {
    if (selectedStartDate && selectedEndDate) {
      const newFilteredDays = daysInMonth.filter(
        (day) => day >= selectedStartDate && day <= selectedEndDate
      );
      setFilteredDays(newFilteredDays);
    } else {
      setFilteredDays(daysInMonth);
    }
  };

  const extendUserSchedule = async (userId, extensionDays) => {
    const daysInCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();

    let consecutiveCounter = 0;
    let lastConsecutiveDay = null;
    let month = null;
    let year = currentYear;

    for (let i = daysInCurrentMonth; i >= 1; i--) {
      if (scheduleData[userId]?.schedules?.[i]) {
        consecutiveCounter++;
        if (!lastConsecutiveDay) lastConsecutiveDay = i;
        if (consecutiveCounter === extensionDays) break;
      } else {
        consecutiveCounter = 0;
        lastConsecutiveDay = null;
      }
    }

    if (consecutiveCounter < extensionDays) {
      toast.warning(
        `Заповніть будь ласка графік на ${extensionDays} днів підряд`
      );
      return;
    }

    const additionalScheduleData = {};

    if (lastConsecutiveDay === daysInCurrentMonth) {
      month = currentMonth === 12 ? 1 : currentMonth + 1;
      year = currentMonth === 12 ? currentYear + 1 : currentYear;
      const daysInNextMonth = new Date(year, month, 0).getDate();
      const daysToCopyFrom = daysInCurrentMonth - extensionDays + 1;

      let daysFilled = 0;

      for (let i = 1; i <= daysInNextMonth; i++) {
        additionalScheduleData[i] = {};

        if (scheduleData[userId]?.schedules?.[daysToCopyFrom + daysFilled]) {
          additionalScheduleData[i] =
            scheduleData[userId]?.schedules?.[daysToCopyFrom + daysFilled];
        }
        daysFilled = (daysFilled + 1) % extensionDays;
      }
    } else {
      month = currentMonth;
      const daysToCopy = lastConsecutiveDay - consecutiveCounter + 1;
      let daysFilled = 0;

      for (let i = lastConsecutiveDay + 1; i <= daysInCurrentMonth; i++) {
        if (scheduleData[userId]?.schedules?.[daysToCopy + daysFilled]) {
          additionalScheduleData[i] =
            scheduleData[userId]?.schedules?.[daysToCopy + daysFilled];
        }
        daysFilled = (daysFilled + 1) % extensionDays;
      }
    }

    const prepareData = await prepareContinueDataForServer(
      additionalScheduleData,
      userId,
      month,
      year
    );
    return await continueSchedule(prepareData, userId);
  };

  const prepareContinueDataForServer = (data, userId, month, currentYear) => {
    const result = [];

    for (const [day, details] of Object.entries(data)) {
      if (details.timeSlots) {
        details.timeSlots.forEach((slot) => {
          const startTime = slot.start?.trim() || null;
          const endTime = slot.end?.trim() || null;
          const categoryId = slot?.schedule_category_id;

          if (!categoryId) {
            toast.error(
              `${scheduleText[language][0]?.categoryIdMissing} ${day}`
            );
            return;
          }

          if (
            ((categoryId >= 6 && categoryId <= 11) || categoryId === 18) &&
            (!startTime || !endTime)
          ) {
            toast.error(
              language === "russian"
                ? `Для дня ${day} с идентификатором категории ${categoryId} необходимы поля start_time и end_time.`
                : `Для дня ${day} з ідентифікатором категорії ${categoryId} необхідні поля start_time та end_time.`
            );
            return;
          }

          result.push({
            employee_id: userId,
            day: formatDateSchedule(currentYear, month, day),
            start_time: startTime,
            end_time: endTime,
            id_categorie: categoryId,
            id_status: 2,
          });
        });
      } else {
        result.push({
          employee_id: userId,
          day: formatDateSchedule(currentYear, month, day),
          start_time: null,
          end_time: null,
          id_categorie: null,
          id_status: 2,
        });
      }
    }

    return result;
  };

  const continueSchedule = async (dataToSendToServer) => {
    try {
      const response = await makeRequestSQL.post(
        "/schedule",
        dataToSendToServer
      );

      if (response.status === 204) {
        refetch();
        return toast.success("Дані успішно відправлені на сервер.");
      } else {
        const errorMessage = error.response?.data?.message || error.message;
        return toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return toast.error(errorMessage);
    }
  };

  useEffect(() => {
    setTime(`02:00`);
  }, []);

  return (
    <div>
      <Main>
        {isLoading && <ClipLoader color={"#123abc"} size={50} />}
        <ScheduleMeNavContainer>
          <MonthButton onClick={() => changeMonth(-1)}>
            {scheduleText[language][0]?.prevMonth}
          </MonthButton>
          <MonthButton onClick={() => changeMonth(1)}>
            {scheduleText[language][0]?.nextMonth}
          </MonthButton>
          <EmployeeSelect
            value={selectedJobTitle}
            onChange={(e) => {
              const idJobTitle = Number(e.target.value);
              setSelectedJobTitle(idJobTitle);
            }}
          >
            <DataOption key="0" value={"0"}>
              {language === "russian" ? "Все отделы" : "Всі відділи"}
            </DataOption>
            {informationData &&
              informationData?.jobTitles?.map((title) => (
                <DataOption key={title.id_job_title} value={title.id_job_title}>
                  {language === "russian"
                    ? title.job_title_RU
                    : title.job_title_UA}
                </DataOption>
              ))}
          </EmployeeSelect>
        </ScheduleMeNavContainer>

        <CopyNavContainer>
          <ChooseDataContainer>
            <span>{scheduleText[language][0]?.dateStartPeriod} </span>
            <DataSelect
              value={selectedStartDate || ""}
              onChange={(e) => setSelectedStartDate(Number(e.target.value))}
            >
              <DataOption value="">
                {scheduleText[language][0]?.chooseDate}
              </DataOption>
              {daysInMonth.map((day) => (
                <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.
                  {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                </DataOption>
              ))}
            </DataSelect>
          </ChooseDataContainer>

          <ChooseDataContainer>
            <span>{scheduleText[language][0]?.dateEndPeriod} </span>
            <DataSelect
              value={selectedEndDate || ""}
              onChange={(e) => setSelectedEndDate(Number(e.target.value))}
            >
              <DataOption value="">
                {scheduleText[language][0]?.chooseDate}
              </DataOption>
              {daysInMonth.map((day) => (
                <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.
                  {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                </DataOption>
              ))}
            </DataSelect>
          </ChooseDataContainer>

          <MonthButton onClick={handleApplyCustomSchedule}>
            {scheduleText[language][0]?.changeSchedule}
          </MonthButton>
        </CopyNavContainer>

        {isModalOpen && (
          <Modal closeModal={() => setModalOpen(false)}>
            <AddScheduleContainerModal>
              <h2>
                День: {selectedDay}.{currentMonth}
              </h2>
              <EmployeeSelect
                value={
                  selectedCategory ? selectedCategory?.schedule_category_id : ""
                }
                onChange={(e) => {
                  const categoryId = Number(e.target.value);
                  const categoryObj = allCategories?.find(
                    (cat) => cat.schedule_category_id === categoryId
                  );
                  setSelectedCategory(categoryObj);
                }}
                style={{
                  marginBottom: "15px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <DataOption>
                  {scheduleText[language][0]?.chooseCategory}
                </DataOption>
                {allCategories?.map((category) => (
                  <DataOption
                    key={category?.schedule_category_id}
                    value={category?.schedule_category_id}
                  >
                    {language === "russian"
                      ? category?.schedule_categorie_name_RU
                      : category?.schedule_categorie_name_UA}
                  </DataOption>
                ))}
              </EmployeeSelect>

              {selectedCategory &&
                (selectedCategory.schedule_category_id <= 10 ||
                  selectedCategory.schedule_category_id >= 18) &&
                timeSlots?.map((slot, index) => (
                  <AddedTimeContainer key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddedTimeInput
                        type="time"
                        value={slot.start ? slot.start : time}
                        step="300"
                        onChange={(e) =>
                          updateTimeSlot(index, "start", e.target.value)
                        }
                      />
                      -
                      <AddedTimeInput
                        type="time"
                        value={slot.end ? slot.end : time}
                        step="300"
                        onChange={(e) =>
                          updateTimeSlot(index, "end", e.target.value)
                        }
                      />
                      {slot?.start && slot?.end && (
                        <div>
                          <IconButton
                            onClick={() =>
                              updateSingleSlotToServer(slot, index)
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            <MdPublishedWithChanges size="28px" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </AddedTimeContainer>
                ))}

              <SaveButton
                onClick={addTimeSlot}
                disabled={timeSlots?.some((slot) => !slot.start || !slot.end)}
              >
                {scheduleText[language][0]?.addSlot}
              </SaveButton>

              <SaveButton
                onClick={handleSave}
                disabled={!areTimeSlotsValid()}
                style={{ marginTop: "10px" }}
              >
                {scheduleText[language][0]?.save}
              </SaveButton>

              <SaveButton
                onClick={() => handleConfirmDelete()}
                style={{ marginTop: "10px" }}
              >
                {scheduleText[language][0]?.deleteSchedule}
              </SaveButton>
            </AddScheduleContainerModal>
          </Modal>
        )}
        <h1>
          {scheduleText[language][0]?.scheduleForMonth}{" "}
          {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}-{currentYear}
        </h1>
        <TableContainer>
          <ScheduleTable>
            <Thead>
              <ScheduleHeadTr>
                <ScheduleTh>#</ScheduleTh>
                <ScheduleTh>{scheduleText[language][0]?.fullName}</ScheduleTh>
                <ScheduleTh>{scheduleText[language][0]?.groupe}</ScheduleTh>

                {filteredDays?.map((day, idx) => {
                  const adjustedIdx = (idx + firstDayOfMonth - 1) % 7;
                  return (
                    <ScheduleTh key={idx}>
                      {
                        weekDays[language][0][
                          Object.keys(weekDays[language][0])[adjustedIdx]
                        ]
                      }
                      <br />
                      {day >= 10 ? day : `0${day}`}.
                      {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                    </ScheduleTh>
                  );
                })}

                <ScheduleTh
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "160px",
                  }}
                >
                  Продовжити графік
                </ScheduleTh>
              </ScheduleHeadTr>
            </Thead>
            <tbody>
              {Object.entries(combinedData).map(
                ([employeeId, employeeDetails], rowIndex) => {
                  return (
                    <ScheduleTr key={employeeId}>
                      <ScheduleTd>{rowIndex + 1}</ScheduleTd>
                      <ScheduleTd>{employeeDetails.name}</ScheduleTd>
                      <ScheduleTd>{employeeDetails.group}</ScheduleTd>
                      {filteredDays.map((day) => {
                        const schedule = employeeDetails?.schedules[day];
                        let categoryId = 0;

                        if (schedule?.timeSlots?.length > 0) {
                          const validSlot = schedule.timeSlots.find(
                            (slot) => slot?.schedule_category_id > 0
                          );
                          categoryId = validSlot?.schedule_category_id || 0;
                        }

                        return (
                          <ScheduleTd
                            key={day}
                            onClick={
                              day ? () => handleDayClick(day, employeeId) : null
                            }
                            categoryId={categoryId}
                          >
                            {schedule?.timeSlots?.map((slot, index) => (
                              <React.Fragment key={index}>
                                {((slot?.schedule_category_id > 6 &&
                                  slot?.schedule_category_id < 11) ||
                                  slot?.schedule_category_id === 18) && (
                                  <CategoryContainer
                                    style={{ marginTop: "8px" }}
                                  >
                                    {language === "russian"
                                      ? slot?.schedule_categorie_name_RU
                                      : slot?.schedule_categorie_name_UA}
                                  </CategoryContainer>
                                )}
                                {slot?.schedule_category_id >= 11 &&
                                  slot?.schedule_category_id <= 18 && (
                                    <div>
                                      {language === "russian"
                                        ? slot?.schedule_categorie_name_RU
                                        : slot?.schedule_categorie_name_UA}
                                    </div>
                                  )}
                                {slot.start && slot.end && (
                                  <div>
                                    {slot.start}-{slot.end}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </ScheduleTd>
                        );
                      })}

                      <ScheduleTd
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "180px",
                        }}
                      >
                        <MonthButton
                          onClick={() => extendUserSchedule(employeeId, 7)}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <MdOutlineAddCircle /> 7
                        </MonthButton>
                        <MonthButton
                          onClick={() => extendUserSchedule(employeeId, 4)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <MdOutlineAddCircle /> 4
                        </MonthButton>
                        <TrashButton
                          onClick={() =>
                            handleDeleteScheduleForMonth(employeeId)
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaTrash />
                        </TrashButton>
                      </ScheduleTd>
                    </ScheduleTr>
                  );
                }
              )}
            </tbody>
          </ScheduleTable>
        </TableContainer>
      </Main>
      <ToastContainer autoClose={4000} />
    </div>
  );
};
