import { Main } from '../../Main/Main';
import { useState, useEffect, useContext } from 'react';
import { Modal } from '../../Modal/Modal';
import { useMutation } from 'react-query';
import {
  formatDateSchedule,
  formatDateToInput,
} from '../../../helpers/dateAndTime/formatDate';
import { ClipLoader } from 'react-spinners';
import {
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  ScheduleHeadTr,
  CancelButton,
  ButtonContainer,
  SaveButton,
  AddedTimeContainer,
  AddedTimeInput,
  MonthButton,
  ScheduleMeNavContainer,
  DataSelect,
  DataOption,
  StatusSelect,
  StatusOption,
  ChooseDataContainer,
  CopyNavContainer,
  AddScheduleContainerModal,
  CategoryContainer,
  CategorySelect
} from './ScheduleMe.styled';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from '../../Buttons/IconButton.styled';
import { MdPublishedWithChanges } from 'react-icons/md';
import { weekDays, scheduleText } from '../../../services/componentText';
import { deleteScheduleByDay, fetchMySchedule, fetchStatuses, fetchCategories, updateSchedule, createScedule } from '../../../api/schedule';

export const ScheduleMe = () => {
  const { language, currentUser } = useContext(UserContext);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [timeSlots, setTimeSlots] = useState([{ start: '', end: '' }]);
  const [initialData, setInitialData] = useState({});
  const [changedScheduleData, setChangedScheduleData] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('2');
  const [errorCount, setErrorCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedCopyDate, setSelectedCopyDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedScheduleID, setSelectedScheduleID] = useState(null);
  const [hasDataExtended, setHasDataExtended] = useState(false);
  const [time, setTime] = useState('');
  const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
  const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
  const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);
  const access_level_d = Number(process.env.REACT_APP_D_LEVEL_ACCESS);
  const access_level_i = Number(process.env.REACT_APP_I_LEVEL_ACCESS);
  const access_level_l = Number(process.env.REACT_APP_L_LEVEL_ACCESS);
  const access_level_m = Number(process.env.REACT_APP_M_LEVEL_ACCESS);

  const handleConfirmDelete = async () => {
    const fullDate = `${currentYear}-${currentMonth}-${selectedDay}`;

      const response = await deleteScheduleByDay({       
        day: await formatDateToInput(fullDate),
        status:
          currentUser?.access_level === access_level_e ||
          currentUser?.access_level === access_level_f ||
          currentUser?.access_level === access_level_g ||
          currentUser?.access_level ===  access_level_d ||
          currentUser?.access_level ===  access_level_i ||
          currentUser?.access_level === access_level_l || 
          currentUser?.access_level === access_level_m 
            ? 2
            : 1,
      })

      if(response.status === 200) {
        setModalOpen(false);
        refetch();
      }
  };

  const fetchCurrentSchedule = async () => {
    if (hasDataExtended) return;

    const response = await fetchMySchedule({
        year: currentYear,
        month: currentMonth,
        id_status: selectedStatus,
    });

    setErrorCount(0);
    setIsFetching(false);

    if(response?.length > 0) {
      const formattedData = response?.reduce((acc, item) => {
        const date = new Date(item.day);
        const monthNumber = date.getMonth() + 1;
        const dayNumber = date.getDate();
  
        if (!acc[monthNumber]) {
          acc[monthNumber] = {};
        }
  
        if (!acc[monthNumber][dayNumber]) {
          acc[monthNumber][dayNumber] = {
            timeSlots: [],
            id_schedule: null,
          };
        }
  
        const currentDay = acc[monthNumber][dayNumber];
  
        currentDay.id_schedule = item.id_schedule;
  
        currentDay.timeSlots.push({
          start: item.start_time?.slice(0, 5) || null,
          end: item.end_time?.slice(0, 5) || null,
          schedule_category_id: item.id_categorie,
          schedule_categorie_name_UA: item.schedule_categorie_name_UA,
          schedule_categorie_name_RU: item.schedule_categorie_name_RU,
        });
  
        return acc;
      }, {});
      return formattedData;
    }
  };

  const updateSingleSlotToServer = async (slot, index) => {
    const dataForServer = prepareDataForSingleSlot(slot);

      if (dataForServer?.id_schedule) {
        await updateSchedule({id_schedule: dataForServer.id_schedule, data: dataForServer})
      } else {
        await createScedule(dataForServer);
      }

      setErrorCount(0);
      setIsFetching(false);
      refetch();
  };

  const { data: categories } = useQuery('categoriesData', fetchCategories);
  const { data: statusesData } = useQuery('statusesData', fetchStatuses);

  const {
    data: schedule,
    refetch,
    isLoading,
    error,
  } = useQuery(
    ['scheduleData', currentYear, currentMonth, selectedStatus],
    () => fetchCurrentSchedule(currentYear, currentMonth, selectedStatus),
  );

  const MAX_RETRIES = 1;
  const RETRY_DELAY = 180000;

  useEffect(() => {
    if (error && !isFetching && errorCount < MAX_RETRIES) {
      setIsFetching(true);

      setTimeout(() => {
        refetch({
          year: currentYear,
          month: currentMonth,
          status: selectedStatus,
        });
        setErrorCount(prev => prev + 1);
      }, RETRY_DELAY);
    } else if (errorCount >= MAX_RETRIES) {
      setErrorCount(0);
      setIsFetching(false);
      if (selectedStatus === 1) {
        toast.error(scheduleText[language][0]?.errorStatusOne);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    error,
    currentYear,
    currentMonth,
    selectedStatus,
    errorCount,
    isFetching,
  ]);

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    setDaysInMonth([...Array(days).keys()].map(day => day + 1));
    const mockData = {};
    setScheduleData(mockData);
    setInitialData(mockData);
  }, [currentMonth, currentYear]);

  useEffect(() => {
    if (error) {
      toast.error(scheduleText[language][0]?.errorResponse, error);
    }
    if (schedule) {
      setScheduleData(schedule);
      setInitialData(schedule);
      setIsFetching(false);
    }

    if (categories) {
      setAllCategories(categories);
    }
    if (statusesData) {
      setStatuses(statusesData);
    }
  }, [schedule, error, language, categories, statusesData]);

  const updateNewSchedule = () => {
    setChangedScheduleData(prev => {
      let updatedData = JSON.parse(JSON.stringify(prev));

      if (!updatedData[currentMonth]) {
        updatedData[currentMonth] = {};
      }

      updatedData[currentMonth][selectedDay] = {
        timeSlots: timeSlots,
        category: selectedCategory,
      };

      return updatedData;
    });

    setSelectedDay(null);
    setTimeSlots([{ start: '', end: '' }]);
    setModalOpen(false);
  };

  const addTimeSlot = () => {
    setTimeSlots(prev => [...prev, { start: '', end: '' }]);
  };


  const handleDayClick = day => {
    setSelectedDay(day);
    const dayDetails = scheduleData[currentMonth]
      ? scheduleData[currentMonth][day]
      : null;

    if (dayDetails?.timeSlots) {
      setTimeSlots(dayDetails?.timeSlots);
    } else {
      setTimeSlots([{ start: '', end: '' }]);
    }

    setSelectedScheduleID(dayDetails?.id_schedule);

    setModalOpen(true);
  };

  const mutation = useMutation(createScedule, {
    onSuccess: () => {
      toast.success(scheduleText[language][0]?.successSchedule);
      refetch();
    },
    onError: error => {
      toast.error(scheduleText[language][0]?.errorSchedule, error);
    },
  });

  const updateTimeSlot = (index, field, value) => {
    const updatedSlots = [...timeSlots];
    if (field === 'end' && value < updatedSlots[index].start) {
      toast.warning(scheduleText[language][0]?.warningEndSlot);
      return;
    }

    if (
      field === 'start' &&
      updatedSlots[index].end &&
      value > updatedSlots[index].end
    ) {
      toast.warning(scheduleText[language][0]?.warningStartSlot);
      return;
    }

    if (selectedCategory?.schedule_category_id >= 11) {
      const dataToSend = prepareDataForServer({
        [selectedDay]: timeSlots,
      });

      createScedule(dataToSend)
        .then(() => {
          toast.success(scheduleText[language][0]?.successResponseDataBase);
        })
        .catch(() => {
          const errorMessage = error.response?.data?.message || error.message;
          toast.error(errorMessage);
        });
    }

    updatedSlots[index][field] = value;
    setTimeSlots(updatedSlots);
  };

  const resetChanges = () => {
    setHasDataExtended(false);
    setChangedScheduleData({});
    setScheduleData(initialData);
  };

  const handleSave = () => {
    const fullScheduleData = prepareDataForServer(changedScheduleData);
    mutation.mutate(fullScheduleData);
    setHasDataExtended(false);
    setChangedScheduleData({});
  };

  const prepareDataForServer = data => {
    const result = [];

    for (const [month, monthDetails] of Object.entries(data)) {
        for (const [day, details] of Object.entries(monthDetails)) {
            let categoryIds;

            if (details.category) {
                categoryIds = [details.category.schedule_category_id];
            } else if (details.timeSlots) {
                categoryIds = details.timeSlots.map(slot => slot.schedule_category_id);
            }

            if (!categoryIds || categoryIds.length === 0) {
                toast.error(`${scheduleText[language][0]?.categoryIdMissing} ${day}`);
                continue;
            }

            categoryIds.forEach(categoryId => {
                if (categoryId < 11 && details.timeSlots) {
                    details.timeSlots.forEach(slot => {
                        const startTime = slot.start?.trim();
                        const endTime = slot.end?.trim();

                        if (startTime === undefined || endTime === undefined) {
                            toast.error(
                                language === 'russian'
                                    ? `Для дня ${day} с идентификатором категории ${categoryId} необходимы поля start_time и end_time.`
                                    : `Для дня ${day} з ідентифікатором категорії ${categoryId} необхідні поля start_time та end_time.`,
                            );
                            return;
                        }

                        result.push({
                            day: formatDateSchedule(currentYear, month, day), // Fix: use 'month' instead of 'currentMonth'
                            start_time: startTime,
                            end_time: endTime,
                            id_categorie: categoryId,
                            id_status:
                                currentUser?.access_level === access_level_e ||
                                currentUser?.access_level === access_level_f ||
                                currentUser?.access_level === access_level_g ||
                                currentUser?.access_level === access_level_d||
                                currentUser?.access_level ===  access_level_i ||
                                currentUser?.access_level === access_level_l || 
                                currentUser?.access_level === access_level_m
                                    ? 2
                                    : 1,
                        });
                    });
                } else {
                    result.push({
                        day: formatDateSchedule(currentYear, month, day), // Fix: use 'month' instead of 'currentMonth'
                        start_time: details.start_time || null,
                        end_time: details.end_time || null,
                        id_categorie: categoryId,
                        id_status:
                            currentUser?.access_level === access_level_e ||
                            currentUser?.access_level === access_level_f ||
                            currentUser?.access_level === access_level_g ||
                            currentUser?.access_level === access_level_d||
                            currentUser?.access_level ===  access_level_i ||
                            currentUser?.access_level === access_level_l || 
                            currentUser?.access_level === access_level_m
                                ? 2
                                : 1,
                    });
                }
            });
        }
    }

    return result;
};


  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay();
  const correctedFirstDay = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;

  const daysWithEmptySlots = new Array(7).fill(null);

  daysInMonth.forEach((day, index) => {
    const position = correctedFirstDay - 1 + index;
    daysWithEmptySlots[position] = day;
  });

  const areTimeSlotsValid = () => {
    if (selectedCategory?.schedule_category_id >= 11) {
      return true;
    }

    return timeSlots.every(slot => slot.start < slot.end);
  };

  const chunkArray = (array, chunkSize) => {
    const results = [];
    while (array.length) {
      const chunk = array.splice(0, chunkSize);

      results.push(chunk);
    }
    return results;
  };

  const chunkedDays = chunkArray(daysWithEmptySlots, 7);
  const missingCells = 7 - chunkedDays[chunkedDays.length - 1].length;
  for (let i = 0; i < missingCells; i++) {
    chunkedDays[chunkedDays.length - 1].push(null);
  }

  const changeMonth = direction => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(prev => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(prev => prev - 1);
    } else {
      setCurrentMonth(prev => prev + direction);
    }
  };

  const handleApplyCustomSchedule = () => {
    if (!selectedCopyDate || !selectedStartDate || !selectedEndDate) return;

    const copyData =
      changedScheduleData[currentMonth]?.[selectedCopyDate] ||
      scheduleData[currentMonth]?.[selectedCopyDate];
    if (!copyData) return;

    let updatedScheduleData = { ...changedScheduleData };

    if (!updatedScheduleData[currentMonth]) {
      updatedScheduleData[currentMonth] = {};
    }

    for (let i = selectedStartDate; i <= selectedEndDate; i++) {
      if (
        updatedScheduleData[currentMonth][i] &&
        updatedScheduleData[currentMonth][i].category
      ) {
        updatedScheduleData[currentMonth][i] = {
          ...copyData,
          category: updatedScheduleData[currentMonth][i].category,
        };
      } else {
        updatedScheduleData[currentMonth][i] = { ...copyData };
      }
    }

    setChangedScheduleData(updatedScheduleData);
  };

  const prepareDataForSingleSlot = slot => {
    return {
      id_schedule: slot.id || selectedScheduleID,
      start_time: slot?.start.trim(),
      end_time: slot?.end.trim(),
      id_categorie:
        slot.schedule_category_id || selectedCategory?.schedule_category_id,
      id_status:
        currentUser?.access_level === access_level_e ||
        currentUser?.access_level === access_level_f ||
        currentUser?.access_level === access_level_g||
        currentUser?.access_level === access_level_d ||
        currentUser?.access_level ===  access_level_i ||
        currentUser?.access_level === access_level_l || 
        currentUser?.access_level === access_level_m
          ? 2
          : 1,
      day: selectedDay,
    };
  };

  const extendSchedule = extensionDays => {
    const daysInCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();

    let consecutiveCounter = 0;
    let lastConsecutiveDay = null;
    let year = currentYear;

    for (let i = daysInCurrentMonth; i >= 1; i--) {
        if (
            changedScheduleData[currentMonth]?.[i] ||
            scheduleData[currentMonth]?.[i]
        ) {
            consecutiveCounter++;
            if (!lastConsecutiveDay) lastConsecutiveDay = i;
            if (consecutiveCounter === extensionDays) break;
        } else {
            consecutiveCounter = 0;
            lastConsecutiveDay = null;
        }
    }

    if (consecutiveCounter < extensionDays) {
        toast.warning(`Cannot find a consecutive ${extensionDays}-day schedule.`);
        return;
    }

    const updatedScheduleData = JSON.parse(JSON.stringify(scheduleData));

    if (!updatedScheduleData[currentMonth])
        updatedScheduleData[currentMonth] = {};
    Object.assign(
        updatedScheduleData[currentMonth],
        changedScheduleData[currentMonth],
    );

    if (lastConsecutiveDay === daysInCurrentMonth) {
        const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        year = currentMonth === 12 ? currentYear + 1 : currentYear;
        const daysInNextMonth = new Date(year, nextMonth, 0).getDate();
        const daysToCopyFrom = daysInCurrentMonth - extensionDays + 1;

        let daysFilled = 0;
        if (!updatedScheduleData[nextMonth]) updatedScheduleData[nextMonth] = {};
        for (let i = 1; i <= daysInNextMonth; i++) {
            updatedScheduleData[nextMonth][i] =
                updatedScheduleData[currentMonth][daysToCopyFrom + daysFilled];
            daysFilled = (daysFilled + 1) % extensionDays;
        }

        setCurrentMonth(prev => (prev === 12 ? 1 : prev + 1));
        setCurrentYear(prev => (currentMonth === 12 ? prev + 1 : prev));
    } else {
        const daysToCopy = lastConsecutiveDay - consecutiveCounter + 1;
        let daysFilled = 0;
        for (let i = lastConsecutiveDay + 1; i <= daysInCurrentMonth; i++) {
            if (!updatedScheduleData[currentMonth][i]) {
                updatedScheduleData[currentMonth][i] =
                    updatedScheduleData[currentMonth][daysToCopy + daysFilled];
            }
            daysFilled = (daysFilled + 1) % extensionDays;
        }
    }

    // Сортування та зміна schedule_category_id
    Object.keys(updatedScheduleData).forEach(month => {
        Object.keys(updatedScheduleData[month]).forEach(day => {
            const timeSlots = updatedScheduleData[month][day].timeSlots;
            if (timeSlots) {
                timeSlots.sort((a, b) => {
                    if (a.start && b.start) {
                        return a.start.localeCompare(b.start);
                    }
                    return 0;
                });

                timeSlots.forEach(slot => {
                    if (slot.schedule_category_id !== 14) {
                        slot.schedule_category_id = 6;
                    }
                });
            }
        });
    });

    setChangedScheduleData(updatedScheduleData);
    setHasDataExtended(true);
};

  useEffect(() => {
    setTime(`02:00`);
  }, []);

  return (
    <div>
      <Main>
        {isLoading && <ClipLoader color={'#123abc'} size={50} />}
        <ScheduleMeNavContainer>
          <MonthButton onClick={() => changeMonth(-1)}>
            {scheduleText[language][0]?.prevMonth}
          </MonthButton>
          <MonthButton onClick={() => changeMonth(1)}>
            {scheduleText[language][0]?.nextMonth}
          </MonthButton>
          <StatusSelect
            value={selectedStatus || ''}
            onChange={e => setSelectedStatus(e.target.value)}
          >
            {statuses.map(status => (
              <StatusOption key={status.status_id} value={status.status_id}>
                {language === 'russian'
                  ? status?.status_name_RU
                  : status?.status_name_UA}
              </StatusOption>
            ))}
          </StatusSelect>
        </ScheduleMeNavContainer>

        <CopyNavContainer>
          <ChooseDataContainer>
            <span>{scheduleText[language][0]?.dateForCopy}</span>
            <DataSelect
              value={selectedCopyDate || ''}
              onChange={e => setSelectedCopyDate(Number(e.target.value))}
            >
              <DataOption value="">
                {scheduleText[language][0]?.chooseDate}
              </DataOption>
              {daysInMonth.map(day => (
                <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                </DataOption>
              ))}
            </DataSelect>
          </ChooseDataContainer>
          <ChooseDataContainer>
            <span>{scheduleText[language][0]?.dateStartPeriod}</span>
            <DataSelect
              value={selectedStartDate || ''}
              onChange={e => setSelectedStartDate(Number(e.target.value))}
            >
              <DataOption value="">
                {scheduleText[language][0]?.chooseDate}
              </DataOption>
              {daysInMonth.map(day => (
                <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                </DataOption>
              ))}
            </DataSelect>
          </ChooseDataContainer>

          <ChooseDataContainer>
            <span>{scheduleText[language][0]?.dateEndPeriod} </span>
            <DataSelect
              value={selectedEndDate || ''}
              onChange={e => setSelectedEndDate(Number(e.target.value))}
            >
              <DataOption value="">
                {scheduleText[language][0]?.chooseDate}
              </DataOption>
              {daysInMonth.map(day => (
                <DataOption key={day} value={day}>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                </DataOption>
              ))}
            </DataSelect>
          </ChooseDataContainer>
          <MonthButton onClick={handleApplyCustomSchedule}>
            {scheduleText[language][0]?.changeSchedule}
          </MonthButton>

          <MonthButton onClick={() => extendSchedule(7)}>
            Продовжити графік
          </MonthButton>
          <MonthButton onClick={() => extendSchedule(4)}>
            Продовжити графік 2/2
          </MonthButton>
        </CopyNavContainer>
        {isModalOpen && (
          <Modal closeModal={() => setModalOpen(false)}>
            <AddScheduleContainerModal>
              <h2>
                День: {selectedDay >= 10 ? selectedDay : `0${selectedDay}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </h2>
              <CategorySelect
                value={
                  selectedCategory ? selectedCategory?.schedule_category_id : ''
                }
                onChange={e => {
                  const categoryId = Number(e.target.value);
                  const categoryObj = allCategories?.find(
                    cat => cat.schedule_category_id === categoryId,
                  );
                  setSelectedCategory(categoryObj);
                }}
                style={{
                  marginBottom: '15px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <DataOption key="6">
                  {scheduleText[language][0]?.chooseCategory}
                </DataOption>
                {allCategories?.map(category => (
                  <DataOption
                    key={category?.schedule_category_id}
                    value={category?.schedule_category_id}
                  >
                    {language === 'russian'
                      ? category?.schedule_categorie_name_RU
                      : category?.schedule_categorie_name_UA}
                  </DataOption>
                ))}
              </CategorySelect>

              {selectedCategory &&
                (selectedCategory.schedule_category_id <= 10 ||
                  selectedCategory.schedule_category_id >= 18) &&
                timeSlots?.map((slot, index) => (
                  <AddedTimeContainer key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <AddedTimeInput
                        type="time"
                        value={slot.start ? slot.start : time}
                        onChange={e =>
                          updateTimeSlot(index, 'start', e.target.value)
                        }
                      />
                      -
                      <AddedTimeInput
                        type="time"
                        value={slot.end ? slot.end : time}
                        onChange={e =>
                          updateTimeSlot(index, 'end', e.target.value)
                        }
                      />
                      {slot?.start && slot.end && (
                        <div>
                          {selectedStatus === '1' && (
                            <IconButton
                              onClick={() =>
                                updateSingleSlotToServer(slot, index)
                              }
                              style={{ marginBottom: '10px' }}
                            >
                              <MdPublishedWithChanges size="28px" />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </div>
                  </AddedTimeContainer>
                ))}

              <SaveButton
                onClick={addTimeSlot}
                disabled={timeSlots?.some(slot => !slot.start || !slot.end)}
              >
                {scheduleText[language][0]?.addSlot}
              </SaveButton>

              <SaveButton
                onClick={updateNewSchedule}
                style={{ marginTop: '10px' }}
                disabled={!areTimeSlotsValid()}
              >
                {scheduleText[language][0]?.save}
              </SaveButton>
              {selectedStatus === '1' && (
                <SaveButton
                  onClick={() => handleConfirmDelete()}
                  style={{ marginTop: '10px' }}
                >
                  {scheduleText[language][0]?.deleteSchedule}
                </SaveButton>
              )}
            </AddScheduleContainerModal>
          </Modal>
        )}
        <h1>
          {scheduleText[language][0]?.scheduleForMonth} {currentMonth}-
          {currentYear}
        </h1>
        <ScheduleTable>
          <thead>
            <ScheduleHeadTr>
              {Object.values(weekDays[language][0])?.map((day, idx) => {
                return <ScheduleTh key={idx+1}>{day}</ScheduleTh>;
              })}
            </ScheduleHeadTr>
          </thead>

          <tbody>
            {chunkedDays?.map((week, weekIdx) => (
              <ScheduleTr key={weekIdx}>
                {week.map((day, idx) => {
                  return (
                    <ScheduleTd
                      onClick={day ? () => handleDayClick(day) : null}
                      key={idx}
                    >
                      {day ? (
                        <>
                  {day >= 10 ? day : `0${day}`}.{currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                          <br />
                          {(changedScheduleData[currentMonth] &&
                            changedScheduleData[currentMonth][day]) ||
                          (scheduleData[currentMonth] &&
                            scheduleData[currentMonth][day]) ? (
                            <>
                              {(
                                changedScheduleData[currentMonth]?.[day]
                                  ?.timeSlots ||
                                scheduleData[currentMonth]?.[day]?.timeSlots
                              )?.map((slot, index) => (
                                <div key={index}>
                                  {slot.schedule_category_id > 6 ||
                                  changedScheduleData[currentMonth]?.[day]
                                    ?.category?.schedule_category_id > 6 ? (
                                    <CategoryContainer
                                      style={{ marginTop: '8px' }}
                                    >
                                      {language === 'russian'
                                        ? slot?.schedule_categorie_name_RU ||
                                          changedScheduleData[currentMonth]?.[
                                            day
                                          ]?.category
                                            ?.schedule_categorie_name_RU
                                        : slot?.schedule_categorie_name_UA ||
                                          changedScheduleData[currentMonth]?.[
                                            day
                                          ]?.category
                                            ?.schedule_categorie_name_UA}
                                    </CategoryContainer>
                                  ) : null}
                                  {slot.start !== null &&
                                    slot.end !== null &&
                                    `${slot.start} - ${slot.end}`}
                                </div>
                              ))}
                            </>
                          ) : null}
                        </>
                      ) : null}
                    </ScheduleTd>
                  );
                })}
              </ScheduleTr>
            ))}
          </tbody>
        </ScheduleTable>
        <ButtonContainer style={{ marginBottom: '60px' }}>
          <SaveButton onClick={handleSave}>
            {scheduleText[language][0]?.makeChanges}
          </SaveButton>
          <CancelButton onClick={resetChanges}>
            {scheduleText[language][0]?.cancel}
          </CancelButton>
        </ButtonContainer>
      </Main>
    </div>
  );
};
