import { UserContext } from "../../Context/UserContext";
import { useQuery } from "react-query";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { productsText } from "../../../services/componentText";
import { Main } from "../../Main/Main";
import { fetchProduct, deleteProduct } from "../../../api/product";
import {
  AddedNewButton,
  NavContainer,
  IconButton,
  TrashButton,
  IconLinkContainer,
  PrdocutAvailabilityContainer,
  PrdocutAvailabilityNameContainer,
  PrdocutAvailabilityStatusContainer
} from "./ProductDetails.styled";
import { BackLink } from "../../BackLink/BackLink";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import css from "./ProductDetails.module.css";
import { fetchProductStatus } from "../../../api/productAvailability";

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ProductDetails = ({ data }) => {
  const { language, currentUser } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    id: data?.id || "",
    title_ua: data?.title_ua || "",
    title_ru: data?.title_ru || "",
    description_ua: data?.description_ua || "",
    description_ru: data?.description_ru || "",
    product_availability: data?.product_availability || [],
  });
  const [isPageRussian, setIsPageRussian] = useState(
    language === "russian" ? true : false
  );

  const isProductList = location?.pathname?.startsWith(
    "/products/product-list"
  );

    const { data: productStatus } = useQuery("productStatus", fetchProductStatus);

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  useEffect(() => {
    if (!isProductList) {
      return;
    }

    const fetchProductData = async () => {
      const productIdFromPath = location?.pathname?.replace(
        /^\/products\/product-list\//,
        ""
      );

      const response = await fetchProduct({ productId: productIdFromPath });

      if (!data && response && response?.data?.length > 0) {
        const fetchedProduct = response?.data[0];

        if(fetchedProduct?.id) {
          setProduct({
            id: fetchedProduct?.id,
            title_ua: fetchedProduct?.title_ua,
            title_ru: fetchedProduct?.title_ru,
            description_ua: fetchedProduct?.description_ua,
            description_ru: fetchedProduct?.description_ru,
            product_availability: fetchedProduct?.product_availability,
          });
        }
      }
    };

    fetchProductData();
  }, [location, isProductList, data]);

  const handleDelete = async (id) => {
    const response = await deleteProduct(id);

    if (response?.status === 200) {
      toast.success(productsText[language][0]?.successRemoved);
      return navigate("/products/product-list");
    }
  };

  const getProductAvailabilityStatus = (id) => {
    const findStatus = productStatus?.filter(
      (item) => Number(item?.product_status_id) === Number(id)
    );

    if(findStatus) {
      return language === "russian"
      ? findStatus[0]?.product_status_title_RU
      : findStatus[0]?.product_status_title_UA;
    }

    return ''
  };

  const checkStatusBackgroundColor = (id) => {
    const findStatus = productStatus?.filter(
      (item) => Number(item?.product_status_id) === Number(id)
    );

    if (findStatus && findStatus[0]?.product_status_id === 1) {
      return "#27ae60";
    }

    if (findStatus && findStatus[0]?.product_status_id === 2) {
      return "rgb(165, 52, 39)";
    }

    if (findStatus && findStatus[0]?.product_status_id === 3) {
      return "rgb(231, 76, 60)";
    }
  };

  return (
    <div>
      <Main>
        <NavContainer>
          {isProductList && <BackLink to="/products/product-list" />}
          <AddedNewButton
            onClick={() => setIsPageRussian(false)}
            active={!isPageRussian}
            disabled={!isPageRussian}
            style={{ marginLeft: "10px" }}
          >
            {productsText[language][0]?.ukraineVersion}
          </AddedNewButton>
          <AddedNewButton
            onClick={() => setIsPageRussian(true)}
            active={isPageRussian}
            disabled={isPageRussian}
          >
            {productsText[language][0]?.russianVersion}
          </AddedNewButton>
          {accessLevel && (
            <IconLinkContainer>
              <IconButton to={`/products/change-product/${product?.id}`}>
                <FaEdit />
              </IconButton>
              <TrashButton onClick={() => handleDelete(product?.id)}>
                <FaTrash />
              </TrashButton>
            </IconLinkContainer>
          )}
        </NavContainer>
        {product && (
          <div style={{ userSelect: "none" }}>
            <h2>{isPageRussian ? product?.title_ru : product?.title_ua}</h2>
            {product?.product_availability &&
              product?.product_availability?.map((item) => (
                <PrdocutAvailabilityContainer
                  key={item?.name}
                  checkStatus={checkStatusBackgroundColor(item?.status)}
                >
                  <PrdocutAvailabilityNameContainer>
                    {item?.name}
                  </PrdocutAvailabilityNameContainer>
                  <PrdocutAvailabilityStatusContainer>
                    {getProductAvailabilityStatus(item?.status)}
                  </PrdocutAvailabilityStatusContainer>
                </PrdocutAvailabilityContainer>
              ))}
            <div
              className={css?.productContent}
              dangerouslySetInnerHTML={{
                __html: isPageRussian
                  ? product?.description_ru
                  : product?.description_ua,
              }}
            />
          </div>
        )}
      </Main>
    </div>
  );
};
